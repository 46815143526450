exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-abstimmungen-abstimmungsprozesse-index-tsx": () => import("./../../../src/pages/abstimmungen/abstimmungsprozesse/index.tsx" /* webpackChunkName: "component---src-pages-abstimmungen-abstimmungsprozesse-index-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-steckbriefe-index-tsx": () => import("./../../../src/pages/steckbriefe/index.tsx" /* webpackChunkName: "component---src-pages-steckbriefe-index-tsx" */),
  "component---src-pages-steckbriefe-meta-json-general-from-federal-state-tsx": () => import("./../../../src/pages/steckbriefe/{metaJson.general__from__federalState}.tsx" /* webpackChunkName: "component---src-pages-steckbriefe-meta-json-general-from-federal-state-tsx" */),
  "component---src-pages-steckbriefe-meta-json-general-to-federal-state-tsx": () => import("./../../../src/pages/steckbriefe/{metaJson.general__to__federalState}.tsx" /* webpackChunkName: "component---src-pages-steckbriefe-meta-json-general-to-federal-state-tsx" */),
  "component---src-pages-steckbriefe-meta-json-json-id-tsx": () => import("./../../../src/pages/steckbriefe/{metaJson.jsonId}.tsx" /* webpackChunkName: "component---src-pages-steckbriefe-meta-json-json-id-tsx" */)
}

